import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Routes,
} from "react-router-dom";

import Header from "./components/Header/Header";
import LandingPage from "./screens/LandingPage/LandingPage";
import AddNewBooks from "./screens/AddNewBooks";
import MyBookstores from "./screens/MyBookstores";
import BookDetails from "./screens/BookDetails/BookDetails";
import "react-day-picker/dist/style.css";

import "./styleGlobal.css";

const App = () => <div className="app">
  <BrowserRouter>
    <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="addNewBooks" element={<AddNewBooks />} />
        <Route path="myBookstores" element={<MyBookstores />} />
        <Route path="bookDetails/:id" element={<BookDetails />} />
      </Routes>
    </BrowserRouter>
</div>;

export { App };
