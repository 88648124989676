import { useEffect, useState } from "react";
import {  Link, useNavigate } from "react-router-dom";

import SearchBar from "../../components/SearchBar/SearchBar";
import { getBooks } from "../../api/Books";

import "./landingPage.css";

const LandingPage = () => {
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBooks = async () => {
      const response = await getBooks();
      setBooks(response.data);
    };  
    fetchBooks();
  }, []);
  if (!books) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <SearchBar />
        <div className="book-tiles">
        {books.map((book) => {
          return (
            <div onClick={() => navigate(`/bookDetails/${book.title}_${book.id}`, {state: { book }})}>
              <div className="book-tile" key={book.id}>{book.title}</div>
            </div>
          )
        }

        )}
        </div>
    </div>

  )
}

export default LandingPage;