import { useCallback ,useEffect, useMemo, useState } from "react";
import {  Link, useHistory, useLocation, useParams } from "react-router-dom";
import Arrow3 from "../../assets/Arrow3.png";
import { getBook, getBookPrices, getExcel } from "../../api/Books";
import { getSellers } from "../../api/Sellers";
import Chart from "../../components/Chart/Chart";
import { addDays } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import "./bookDetails.css";

type BookSeller = {
  id: number
  name: string
  show: boolean
  color: string
}

type Book = {
  authors: string
  id: number
  isbn: string | null
  title: string
}

const BookDetails = () => {
  const location = useLocation();
  const [book, setBook] = useState<Book | undefined>(undefined);
  const [bookPrices, setBookPrices] = useState(null)
  const [sellers, setSellers] = useState(Array<BookSeller>())
  const [labels, setLabels] = useState([])
  const [data, setData] = useState(null)
  const initialRange: DateRange = {
    from: new Date(),
    to: addDays(new Date(), 4)
  }

  const [range, setRange] = useState<DateRange | undefined>(initialRange);
  console.log("range", range)
  const book_id = useMemo(() => {
    return location.pathname.substring(location.pathname.lastIndexOf('_') + 1)

  }, [location])
  useEffect(() => {
    const fetchBooks = async () => {
      const response = await getBook(book_id);
      setBook(response.data);
    };  
    if (location.state) {
      setBook(location.state.book);
    } else {
      fetchBooks();
    }
  }, []);
  
  useEffect(() => {
    const fetchBookPrices = async () => {
      const response = await getBookPrices(book_id)
      setBookPrices(response.data)
    }
    fetchBookPrices()
  }, [])

  useMemo(() => {

    if(bookPrices) {
      async function fetchSellers() {
        const sellers = new Set(bookPrices.map(price => price.seller))
        const sellersString = String([...sellers].join(','))
        const response = await getSellers(sellersString)
        const BookSeller = response.data.map(seller => {
          return {
            id: seller[0],
            name: seller[1],
            show: true,
            color: `#${Math.floor(Math.random()*16777215).toString(16)}`
          } as BookSeller
        
        })
        // setLabels(Array.from(new Set(bookPrices.map(price => price.date))))

        setSellers(BookSeller)
      }
      fetchSellers()
    }
  }, [bookPrices])




  // let labels = []
  useMemo(() => {
  if(!range) {
    const labels = bookPrices?.map(price => price.date)

    setLabels([...new Set(labels)])
  } else {
    const labels = bookPrices?.filter(price => {
      const newDate = new Date(price.date)
      return newDate >= range.from && newDate <= range.to
    }).map(price => price.date)
    setLabels([...new Set(labels)])

  }} ,[range, bookPrices])
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']
  // console.log("new date", new Date(bookPrices?.[0]?.date))


  useEffect(()=> {
    const data = []
    sellers.map(seller => {
      if(!seller.show) return
      data.push( {
        label: seller.name,
        // data: labels.map(() => Math.floor(Math.random() * 10)),
        data: labels.map(label => {
          const price = bookPrices.find(price => price.date === label && price.seller === seller.id)
          console.log("price", price)
          return price ? price.price : null
        }),
        borderColor: seller.color,
        backgroundColor: `${seller.color}50`
    })
  })
  setData(data)
},[labels,sellers])

  if(!data) return <div>Loading...</div>
  console.log("data", data)


  return (
    <div className="book-details container">
      <div className="back-link">
        <Link to="/">
          <img src={Arrow3} alt="back" />
          Back
        </Link>
      </div>
      <div className="data-and-actions">
        <div className="book-data">
          <div className="book-tile">
            {book.title}
          </div>
          <div className="book-des">
            <p>Tltle: {book.title}</p>
            <p>Authors: {book.authors}</p>
            <p>ISBN: {book.isbn}</p>
            <p>Recent price: </p>
          </div>
        </div>

        <div className="book-actions">
          <div className="time-period">
            <div>Choose time period
              <DayPicker mode="range" selected={range} onSelect={setRange} />
            </div>
          </div>
        </div>
          <div className="download-excel" onClick={() => downloadExcel(book, range, sellers)}>
            {/* tutaj sciaganie excela */}
            excel
          </div>
      </div>
      <div className="checkboxes">
        {sellers.map(seller => {
          return (
            <div key={seller.id}>
              <input type="checkbox" id={seller.id} name={seller.name} checked={seller.show} onChange={() => {
                const newSellers = sellers.map(s => {
                  if(s.id === seller.id) {
                    return {
                      ...s,
                      show: !s.show
                    }
                  }
                  return s
                })
                setSellers(newSellers)
              
              }} />
              {/* <label htmlFor={seller.id}>{seller.name}</label> */}
              {seller.name}
            </div>
          )
        })}
      </div>
      <div className="chart">
        <Chart labels={labels} datasets={data} />
      </div>
    </div>

  )
}

const downloadExcel = (
  book: Book,
  dateRange: DateRange | undefined,
  sellers: String,
) => {
  if (!dateRange) {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    getExcel(book, `1900-01-01^${formattedDate}`, sellers)
  }
    else {
  const dateFrom = new Date(dateRange.from)
  const finalDateFrom = `${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}-${dateFrom.getDate()}`
  const dateTo = new Date(dateRange.to)
  const getFinalDateTo = `${dateTo.getFullYear()}-${dateTo.getMonth() + 1}-${dateTo.getDate()}`
  const finalDate = `${finalDateFrom}^${getFinalDateTo}`
    getExcel(book, finalDate, sellers)
    }

  


  
}


export default BookDetails

// https://react-day-picker.js.org/advanced-guides/custom-selections