import axios, {BASE_API_URL} from "../config/axios";

export const getBooks = async () => {
  try {
    return await axios.get('/books/tracked')
  } catch (error) {
    console.error(error)
  }
}

export const getBook = async (id) => {
  try {
    return await axios.get(`/books/tracked/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export const getBookPrices = async (id) => {
  try {
    return await axios.get(`/books/prices/${id}`)
  } catch (error) {
    console.log(error)
  }
}

export const getExcel = async (book, dateRange, sellers) => {
  const sellersIDString = sellers.map(seller => seller.id).join('^')
  const sellersNameString = sellers.map(seller => seller.name).join(',')
  try {
    const response =  await axios.get(`/excel/${book.id}/${dateRange}/${sellersIDString}`, {
      responseType: 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${book.title}: ${dateRange} : ${sellersNameString}.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error(error)
  }
}

