import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },// import "./chart.css";

    // const Chart = () => {
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};


interface ChartProps {
  labels: Array<String>, 
  datasets: Array<{
    label: string,
    data: Float32Array,
    borderColor: string,
    backgroundColor: string,
  }> 
}

const Chart = (props: ChartProps) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  }
  return <Line options={options} data={data} />;
}

export default Chart
