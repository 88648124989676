
import { useState } from "react";
import "./searchBar.css";

import lupa from "../../assets/lupa.png";

const SearchBar = () => {
  const [inputText, setInputText] = useState("");
  return (
    <div className="search-bar">
      <div className="frame">
        {/* <i className="fas fa-search"></i> */}
        <span className="search">
          <img src={lupa} alt="search" />
        </span>
        <input 
          className="input" 
          type="text" 
          placeholder="Search for books"
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
         />
      </div>
    </div>
  );
}

export default SearchBar;